<template>
  <div class="wrapper">
    <div class="sign-in-up forgot-password" id="congratulations">
      <div class="card-wrapper">
        <div class="card-container">
          <div class="card-shadow"></div>
          <div class="card-shadow"></div>
          <div class="card">
            <div class="card-item">
              <div class="card-img">
                <lottie-player
                  autoplay
                  loop
                  src="https://assets7.lottiefiles.com/packages/lf20_f7omhrnq.json"
                  style="height: 100%"
                />
              </div>
              <div class="card-title">
                <h2>Congratulations!</h2>
              </div>
              <p>
                You have completed your application. <br />
                We are reviewing now and will reach out shortly for final steps.
              </p>

              <router-link
                @click.native="fetchCreateAppFlag(true)"
                :to="baseUrl"
                class="btn btn-red"
              >
                <span>Return to home page</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import "@lottiefiles/lottie-player";

export default {
  name: "Congratulations",
  data() {
    return {
      signInBg: "",
      mismoFileCreated: false,
    };
  },
  watch: {
    signInBg(newVal) {
      if (newVal) {
        let theBox = document.getElementById("congratulations");

        theBox.style.backgroundImage = `url(${newVal})`;
        theBox.style.backgroundSize = "cover";
      }
    },
  },
  mounted() {
    this.signInBg = this.getAssets.agentPortalBackground;

    let header = document.querySelector("lottie-player");

    this.$nextTick(() => {
      if (header.shadowRoot) this.addDynamicColorToGif(header);
    });

    this.fetchLoaderStatus(false);
  },
  computed: {
    ...mapGetters(["getCompanyName", "getProspectId", "getOrgId", "getAssets"]),

    baseUrl() {
      return (
        "/" +
        this.getCompanyName +
        "/" +
        this.getProspectId +
        "&" +
        this.getOrgId
      );
    },
  },
  methods: {
    ...mapActions(["fetchCreateAppFlag", "fetchLoaderStatus"]),

    addDynamicColorToGif(header) {
      header.shadowRoot.querySelector(".animation").innerHTML = `<style>
        svg > g > g:nth-child(6),
        svg > g > g:nth-child(12) {
            position: relative;
        }
        svg g > g:nth-child(6) > g:first-child {
            color: black;
        }
        svg > g > g:nth-child(6) > g:first-child > g > path,
        svg > g > g:nth-child(12) > g:first-child > g > path,
        svg > g > g:nth-child(6) > g:nth-child(2) > g > path,
        svg > g > g:nth-child(12) > g:nth-child(2) > g > path,
        svg > g > g:nth-child(6) > g:nth-child(3) > g > path,
        svg > g > g:nth-child(12) > g:nth-child(3) > g > path {
            fill: ${this.getAssets.primaryBrandColor || "#A6383C"};
        }
    </style>`;
    },
  },
};
</script>
